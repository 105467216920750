

@import '~bootstrap/scss/bootstrap.scss';
@import "node_modules/bootstrap/scss/bootstrap";

$black: #000201;

$lilspace: 10px;

a{
  margin-bottom: $lilspace;
}

h1{
  padding-bottom: 20px;
  padding-top: 20px;
  text-align: center;
}

td{
  padding-top: 80px;
}

th{
  margin-right: 10px;
}
.btn{
  margin-right: 8px;
  margin-bottom: 8px;
}

#client_survey_url,
#client_id_param_name{
  margin-bottom: 15px;
}

#completes_header{
  padding-bottom: 10px;
}

#close_project_btn{
  margin-top: 15px;
}


#project_btn_1,
#project_btn_2 {
  margin-bottom: 10px;
  margin-top: 10px;

}



#view_project_title{
  margin-top: 20px;
}

#view_projects_save_btn_1{
  margin-bottom: 5px;
}

#view_projects_save_btn_2{
  margin-bottom: 0px;
}

#target_header{
  margin-top: 15px;
  margin-bottom: 10px;
}

#edit_set_btn{
  margin-top: 10px;
}

#set_trgt_label{
  margin-top: 5px;
}

#target_text{
  margin-left: 5px;
  margin-top: 5px;
  margin-bottom: 10px !important;
}
.badge-danger{

  margin-bottom: 10px;
}

.badge-success{
  margin-left: 0px;
  margin-bottom: 10px;
}

#setup_project_del_btn{
  margin-left: 15px;
  padding-top: 0px;
  padding-bottom: 0px;


}

#setup_project_del_btn:hover{
  pointer-events: none;

}

thead {
  size: absolute;
}

#edit_target_button {
  margin-bottom: 20px;
}

p {
  font-family: arial;
}

#create_a_new_project_btn{
  text-align: center;
}

#project_info_header{
  padding-top: 20px;
}

#project_btn_wrapper{
  text-align: center;
}

.expandable_btns{
  margin-top: 28px;
}
.index_line{
  width: 118em;
  margin-left: 18px;
  margin-bottom: 10px;
}

#setup_container{
margin-left: 26em !important;
padding-right: 15.75em !important;

margin-top: 1em;

}
#view_project_container{
  margin-left: 25em !important;
  padding-right: 12.75em !important;

}

h3{padding-top: 20px;}
#setup_btns_containers{text-align: center;}
#cancel_setup{margin-top: 0px;
padding: 10px 100px !important;
cursor: pointer !important;
}

#reopen_project_btn{
  margin-top: 15px;
}

#supplier_ptag{
  margin-top: 20px;
}

.supplier_btn{
  margin-top: 15px;
}
#new_project_btn_container{
  text-align: center;

}
#YS_URL_FOR_SUPPLIER{
  margin-top: 30px;
}

nav>a{
      cursor: pointer !important;
      font-family:sans-serif !important;
      background: #36bfbf !important;
      border: 1px solid #36bfbf !important;
      color: #fff;
      text-decoration: none !important;
      padding: 13px 66px !important;
      letter-spacing: 2px !important;
      margin-top: 15px !important;
      display: inline-block !important;
      text-align: center !important;
      font-size: 1.2em !important;
}
nav>a:hover,.openbtn:hover,.btn-primary:hover{
  background: #2aa3a3 !important;

}

#list_table{width: 100% !important;}

.openbtn{
  background-color: #36bfbf !important;
  border: none !important;
}

.btn-primary{
  background-color: #36bfbf !important;
  border: none !important;}

.setupbtns{
  cursor: pointer !important;
  font-family:sans-serif !important;
  background: #36bfbf !important;
  border: 1px solid #36bfbf !important;
  color: #fff;
  text-decoration: none !important;
  padding: 10px 63px !important;
  letter-spacing: 2px !important;
  margin-top: 15px !important;
  display: inline-block !important;
  text-align: center !important;

}

#cancel_setup:hover{
  background-color: #dc3545;
    border-color: #dc3545;
}

body{
  font-size: 16px !important;
line-height: 20px!important;
-webkit-font-smoothing: antialiased !important;
-webkit-tap-highlight-color: transparent !important;
font-weight: normal !important;
}



#list_table {
  border: 4px solid #ddd !important;
    height: 550% !important;

}




table {
  text-align: center !important;
  padding-top: 140px;
  font-size: 16px !important;
  border: 4px solid #ddd !important;

}

td:hover{
  background-color: #ffbfa5;
}


.filters-table:hover{
  background-color: white !important;
}



tr:nth-child(even) {
  background-color: #ffded1 !important;
}

th{
  background-color: white !important;
}

.white{
  background-color: white !important;
}

#tester_list{
border: None !important;
}
.add_button{
  margin-right: 20px !important;

}
#copy_btn{
  margin-top: 11px;
}

.nohov_btn:hover{
  opacity: 1;
  background-color: inherit;

}

.admin-table-big-headers{
  text-align: center;
  padding-bottom: 1em!important;
}

.admin-table-headers{
  padding-bottom: 20px;
  padding-top: 15px;
}

.admin-table-btns{
  margin-left: 27.5em;
}
.trafficLight-label {
    font-size: 1.1em !important;
}
#trafficLight-form{
  width: 4%;
}
#param-delete-btn{
  margin-top: 4px !important;
}
#trafficLight-header{
  padding-top: 3em !important;
}
#trafficLight-hr-line{
  margin-bottom: 2em!important;
}
#param-form{
  width: 30%;
}
#gdpr-form{
  width: 30%
}
#page-content-form{
  width: 30%
}
.save-changes-button{
  margin-bottom: 1em;
}
.admin-button{
  margin-top: 0.5em;
}
.no-ab-hov{
  pointer-events: none;
}

.mail-template-modal {
  width: 90%!important;
  max-width: none!important;
}

@media(max-width: 767px) {
  .container {
    max-width: 100%!important;
  }
}